.registerUser-page{

	background-color: #f1f3f7;
	padding:1px;
	
}


.registerUser-mainContainer{
	max-width: 880px!important;
	margin:40px auto!important;
	
	transition: all 2s;
	background-color: #FFFFFF;
	/* padding:30px 100px!important; */
}

.registerUser-form{
	
	transition: all 2s;
}

.registerUser-form .fields {
	
	transition: all 2s;
}

.registerUser-messageContainer{
	transition: all 1.2s;
	height: auto!important;
	min-height:0px;
}

.hasError{

	min-height: 75px;
	height: auto!important;
}

.registerUser-button{
	min-width: 160px;
}

.registerUser-mainContainer-messageContainer{
	transition: all 2s;

}

.consent-mainContainer{
	max-width: 880px!important;
	margin:40px auto!important;
	background-color: #FFFFFF;
	padding:30px 100px!important;
}

.consent-text{
	max-height: 750px;
	overflow-y: scroll;
	font-size: 16px!important;
}

.consent-actions{
	margin:40px 0 20px 0;
}

.beaconReviewDetails-header{
	margin:0px 0 20px 0px!important;
}

.beaconReviewDetails-subheader{
	margin:20px 0 20px 0px!important;
}

.beaconReviewDetails-editLink{
	color:red;
	text-decoration: underline;
	font-size: 16px;
	cursor: pointer;
}

.beaconReviewDetails-Link{
	text-decoration: underline;
	font-size: 16px;
	cursor: pointer;
}

.beaconReviewDetails-mainFormContainer{
	max-width: 880px!important;
	margin:0px auto!important;
	background-color: #FFFFFF;
	padding:30px 20%;
}

@media only screen and (max-width: 768px) {
	.beaconReviewDetails-mainFormContainer{
		padding:0 !important;
	}
}

.form-dropdown-style{

	margin-top:22px!important;
	height: 38px;
	font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    margin: 0;
    outline: 0;
    -webkit-appearance: none;
    tap-highlight-color: rgba(255,255,255,0);
    line-height: 1.21428571em;
    padding: .67857143em 1em;
    font-size: 1em;
    background: #f3f3f3;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: .28571429rem;
    box-shadow: 0 0 0 0 transparent inset;
    transition: color .1s ease,border-color .1s ease;
}

.margin-top-0 {
	margin-top: 0 !important;
}

.registerUserForm-addContactsButton{
	cursor: pointer;
	color:#c0392b;
}

.registerUser-additionnalContactRemove{
	margin-top:5px!important;
	background: none!important;
}

#registerUserForm .contact-number {
	/* display: flex;
	align-items: flex-end; */
	margin-top: 20px !important;
}

.phone-type-other {
	display: flex;
	align-items: flex-end;
}

.revealPassword {
	display: flex;
	align-items : center;
}
.eyeIcon {
	position: relative;
	top: 8px;
}