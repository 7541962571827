
.registerBeacon-page{
	background-color: #f1f3f7;
}

.registerBeacon-topBanner{
	background-color:#0e1f47;
	height: 113px;
}

.registerBeacon-mainFormContainer{
	background-color: #FFFFFF;
	padding:12px 12px 12px 12px;
}

.registerBeacon-subContainer{
	/* padding:12px 44px; */
	padding:0 44px;
}

.newUserNotification{
	background-color: #dde8f1;
	padding:30px 20% 30px 20%!important;
}

.newBeacon-header{
	margin:30px 20px 20px 30px!important;
}

.newBeacon-grid{
	margin-top: 20px!important;
}

.regulation-text{
	max-height: 450px;
	overflow-y: scroll;
	font-size: 16px!important;
}

.registerSingleBeaconForm{
	max-width: 50%!important;
	width: 50%!important;
	text-align: left;
}

.registerSingleBeaconInput{
	max-width: 50%!important;
	width: 50%!important;
	margin:2px auto 20px auto!important;
	text-align: left;
}

.registerSingleBeaconLabel{
	max-width: 50%!important;
	width: 50%!important;
	margin:2px auto 0px auto!important;
	text-align: left;
	color:black;
}

.beaconDetail-form{
	max-width: 650px!important;
	margin: 0 auto;
}

.beaconDetail-form .inline.field>label{
	width: 42%!important;
	text-align: right;
	margin-right: 20px!important;
}

.beaconDetail-form .ui.input{
	width: 250px!important;
}

.beaconDetailform-selectInput{
	width: 250px!important;
}

.export-selectInput {
	width: 100% !important;
}

.registerBeacon-submitContainer{
	width:440px;
	margin: 40px auto 0px auto;
}

.registerBeacon-submitButton{
	width: 100%;
	margin:20px 0 0 0!important;
}

.additional-phone{
	position: relative;
}
.registerUser-additionnalContactRemove{
	position: absolute;
    z-index: 999;
    right: -32px;
    bottom: 16px;
}

@media only screen and (max-width: 768px) {
	.registerBeacon-submitContainer{
		width:auto;
	}
}

.beaconLocation-form{
	width: 100%;
	margin: 0 auto;
}

.imageUpload-preview{
	width: 100%;
	height: 100%;
}

.consent-confirm{
	margin:20px 0 60px 0;
}

.ui.disabled.input, .ui.input:not(.disabled) input[disabled] {
	opacity: .9!important;
}

.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
	pointer-events: none;
	opacity: .9!important;
	color: rgba(0,0,0,.95)!important;
}

.modal-content.hexValidateMessage,
.modal-content.hexValidateMessage h3,
.modal-content.hexValidateMessage p {
	text-align: left !important;
}

@media only screen and (max-width: 768px) {

	.beaconDetail-form .ui.input{
		width: 100%!important;
	}

	.beaconDetail-form .inline.field>label{
		width: 100%!important;
		text-align: left;
		margin-right: 0!important;
	}

	.registerBeacon-mainFormContainer > .steps {
		display: none !important;
	}
	.registerBeacon-subContainer .header .content {
		font-size: 0.8em;
    	line-height: 1em;
	}
	.registerBeacon-subContainer {
		padding: 0 !important;
	}
	.registerBeacon-subContainer .registerBeacon-submitButton {
		margin: 0 !important;
	}
	.registerBeacon-subContainer .stackable > .column {
		padding: 0 !important;
	}
	.registerBeacon-subContainer .steps .step:not(.active) {
		display: none;
	}
	/* .beaconReviewDetails-mainFormContainer {
		padding: 0 !important;
	} */
}

.contact-number {
	/* display: flex;
	align-items: flex-end; */
	margin-top: 20px !important;
}

.registerBeacon-stepComplete{
	transform: translate(15px);
	font-size: 25px!important;
	font-weight: bolder!important;
} 

.beaconReviewDetails-header .sub.header {
	font-weight: bold !important;
	color: rgba(0,0,0,.87) !important;
} 

.beaconReviewDetails-addActivityPrompt{
	min-height: 1rem!important;
}

.imageUpload-segment{
	min-height: 200px!important;
}