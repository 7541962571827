.contact-us-container .header {
	display: block !important;
}
.contactForm{
	padding: 15px;
	text-align: left;
}
.contactForm-Additionnal-content{
	color:blue;
	font-size: 50px;
}
.font-red {
	color: red;
}
.contact-us-container {
	width: auto;
	margin-left: 0;
	margin-right: 0;
}
@media only screen and (max-width: 991px) and (min-width: 768px){
	.contact-us-container {
		width: 723px;
		margin-left: auto!important;
		margin-right: auto!important;
	}
}

@media only screen and (min-width: 992px){
	.contact-us-container {
		width: 933px;
		margin-left: auto!important;
		margin-right: auto!important;
	}
}
