.dashboard-page {
  background-color: #f1f3f7;
  height: 100%;
  display: table;
  width: 100%;
}

.dashboard-container {
  
}

@media only screen and (min-width: 1600px) {
  .dashboard-container {
    width: 1450px!important;
  }
}

@media only screen and (min-width: 2000px) {
  .dashboard-container {
    width: 80%!important;
  }
}

.ui.attached.tabular.menu {
  padding: 0 20px;
}

.dashboardLayout-grid {
  height: 100%;
}

.dashboard-tab {
  height: 100%;
}

@media only screen and (max-width: 530px) {
  .dashboard-tab {
    margin-top:35px!important;
  }
}

/* Reset */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}


.activity-instructions-button {
  position: absolute;
  right: 10px;
  top: 10px;
 
}

.dashboard-activities-addConfirmpopup{
  margin:10px 0 0 0!important;
}

.activity-instructions {
  margin-top: 40px !important;
  color: #0D1E49!important;
  background-color: rgba(116, 185, 255, 0.09)!important;
}

.wrapped {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.dashboard-menuItem {
  width: 24%;
  height: 50px;
  background: none #0d1e49 !important;
  color: #fff !important;
  font-size: 20px;
  margin: 0 5px -1px 5px !important;
  transition: 0.3s !important;
  padding: 0.92857143em 0.92857143em !important;
}

.dashboard-menuItem:hover {
  height: 50px;
  background: none #b9c2d8 !important;
  color: #000 !important;
  font-size: 20px;
}

.active.dashboard-menuItem {
  background: none #fff !important;
  color: #000 !important;
  font-size: 20px;
}
.dashboard-menuItem-icon {
  display: none;
}

.beacons-search-icon {
  padding: 0 10px;
  transition: all 0.5s ease;
}

.beacons-search-icon-rotated {
  transform: rotate(180deg);
}

@media all and (max-width: 1330) {
  .dashboard-menuItem {
    width: 24%;
    height: 50px;
    background: none #0d1e49 !important;
    color: #fff !important;
    font-size: 12px;
    margin: 0 2px -1px 2px !important;
    transition: 0.3s !important;
  }
}

@media all and (max-width: 940) {
  .dashboard-menuItem-icon {
    display: none !important;
  }
}

.dashboard-beacon-clearLink {
  color: red;
  /* margin: 0 0 0 20% !important; */
  width: 100%;
  height: 70px;
  line-height: 40px;
  vertical-align: middle;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

.dashboard-beacon-tableHeader {
  background-color: #0d1e49 !important;
  color: #fff !important;
  padding: 10px 10px !important;
  border: 1px solid #bdc3c7;
  z-index: 2;
}

.dashboard-beacon-tableRow {
  max-height: 35px;
  height: 35px !important;
}

.dashboard-beacon-resultRow .td {
  margin: 10px 10px !important;
}

.dashboard-beacon-resultCell {
  padding: 15px !important;
}

.dashboard-beacons-searchCriteria .icon {
  display: none;
}

.dashboard-beacons-searchCriteria {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.ui.multiple.dropdown > .label {
  background-color: #bababa;
  color: #fff;
  border-radius: 10px;
}

.dashboard-beacon-table {
  cursor: pointer;
  min-height: 500px !important;
}

.dashboard-beacon-resultCell-link {
  text-decoration: underline;
  color: #2f4c9c;
  font-weight: bold;
  cursor: pointer;
}

.dashboard-tab-addButton {
  position: absolute !important;
  top: 20px;
  right: 0px;
  color: red !important;
  font-size: 11px !important;
  background: none !important;
  margin-left: 1em !important;
  text-decoration: underline !important;
}

@media only screen and (max-width: 767px) {
  .dashboard-tab-addButton-mobile {
    color: red !important;
    font-size: 11px !important;
    background: none !important;
    text-decoration: underline !important;
    padding: 0 !important;
  }
}

.dashboard-beacon-editBeacon {
  transition: all 0.5s linear;
}

.dimmed.dimmable>.ui.animating.dimmer, .dimmed.dimmable>.ui.visible.dimmer, .ui.active.dimmer {
  overflow-y: scroll !important;
}

.button-mobile {
  margin: 6px 0 8px 10px !important;
}

.dashboard-beacon-editBeacon-content {
  transition: all 0.5s linear;
  
}

.beacon-searchCriteria {
  display: inline-block;
  width: 100%;
  margin: 0 4px 0 0 !important;
  padding: 5px 5px 0 0 !important;
}

.beacon-searchCriteria-field {
  width: 45% !important;
  min-width: 6em !important;
  display: inline-block !important;
  margin: 0 !important;
  height: 40px;
  border-radius: 0 !important;
  vertical-align: top !important;
}

.beacon-searchCriteria-field .text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.beacon-searchCriteria-input {
  width: 55% !important;
  height: 100% !important;
  display: inline-block !important;
  border-radius: 0 !important;
  
  padding: 0 0 0 2px !important;
}

.beacon-searchCriteria-input input{
  border-radius: 0 !important;
  height: 42px !important;
}

.dashboard-beacons-gridRow {
  padding-top: 0.6rem !important;
  padding-bottom: 0.1rem !important;
}

.pagination-mobile {
  min-width: 100px;
}

.dashboard-beacons-gridRow-last {
  padding-top: 0.1rem !important;
}

.beacon-searchCriteria-input input {
  width: 100% !important;
  height: 40px !important;
  border: solid 1px #dcddde;
}

.beacons-optionsRow {
  min-height: 38px !important;
}

@media all and (max-width: 770) {
  .beacon-searchCriteria-input {
    width: 100% !important;
  }

  .beacon-searchCriteria-field {
    width: 100% !important;
  }
}

@media all and (max-width: 940) {
  .beacon-searchCriteria-input {
    width: 44% !important;
  }

  .beacon-searchCriteria-field {
    width: 44% !important;
  }
}

.SearchCriteriaDisplay-column {
  padding: 0 !important;
}

.beacon-searchCriteria-inputDropdown {
  display: inline-block !important;
  width: 100% !important;
  min-width: 10em !important;
}

.beacon-searchCriteria-closeButton {
  top: -0.5em !important;
  left: 100.5% !important;
  border-radius: 50% !important;
  padding: 3px 4px 2px 4px !important;
}

.beacon-searchCriteria-closeButton i {
  margin: 0 !important;
  color: #fff !important;
  opacity:1important;
  transform:translate(0,-1px)
}

.for-desktop {
  display: block;
}
.for-mobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .ui.menu {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap !important;
  }

  .ui.attached.tabular.menu {
    padding: 0;
  }
  .dashboard-menuItem {
    margin: -1px 0.5% -1px 0.5% !important;
    font-size: 1em;
  }
  .active.dashboard-menuItem {
    font-size: 1em;
  }
  .for-desktop {
    display: none;
  }
  .for-mobile {
    display: block;
  }
  .ui.menu .item {
    height: auto;
  }
}

.ui.selection.dropdown .menu > .item {
  min-height: 30px;
}

.sidescroll {
  overflow: auto;
  max-height: 500px;
}
@media (min-height: 1200px) {
  .sidescroll {
    max-height: 600px;
  }
}
@media (min-height: 1300px) {
  .sidescroll {
    max-height: 700px;
  }
}
.sidescroll table {
  position: relative;
}
.sidescroll table th {
  position: sticky;
  top: 0;
}
.registerBeacon-mainFormContainer .column {
  word-break: break-word;
}
.submitContainer {
  margin-top: 15px;
}

.beacon-page-number > input {
  padding: 5px !important;
  text-align: center !important;
}
/* Chrome, Safari, Edge, Opera */
.beacon-page-number > input::-webkit-outer-spin-button,
.beacon-page-number > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.beacon-page-number > input[type="number"] {
  -moz-appearance: textfield;
}

.ui.table tr td.disabled,
.ui.table tr.disabled td,
.ui.table tr.disabled:hover,
.ui.table tr:hover td.disabled {
  opacity: 0.6;
}
