.beaconDecode-container{
	margin: 50px auto !important;
	max-width: 800px;
}
.beaconDecode-header{
	text-align: center !important;
}
.beaconDecode-table{
	text-align: left!important;
}
.decode-input .ui.label {
	background-color: #192f65;
	color: #FFF;
}
.beaconDecode-table th {
	background-color: #192f65 !important;
	color: #FFF !important;
	border-color: #FFF !important;
}
#decode-disclaimer, #decode-disclaimer *{
	text-align: left !important;
}
.decoded-message, .decoded-message *{
	text-align: left !important;
}