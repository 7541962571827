.font-blue {
    color: #0e1f47 !important;
}

.activityDetail-form .ui.input {
    width: auto !important;
}
.activityDetail-submitContainer {
    width: auto;
    margin: 40px auto 0px auto;
    text-align: center;
}
.activityDetail-submitContainer .ui.button {
    width: 100%;
    margin: 0px !important;
}
/* .activityDetail-submitContainer .ui.button[type="submit"] {
    margin-top: 15px !important;
} */
.activity-label {
    margin: 0 -0.5em !important;
    font-weight: 600;
}
.font-bold {
    font-weight: 600;
}
.removeSecondContac-container {
    position: relative;
}
.removeSecondContact {
    cursor: pointer;
    position: absolute;
    right: -28px;
    bottom: 22px;
}
@media only screen and (max-width: 767px){
    .removeSecondContac-container.ui.container {
        margin: 15px 0 0 0 !important;
    }
}

.auto-min-width > .selection.dropdown {
    min-width: auto !important;
}
.cancel-add-edit {
    color: #ea4240;
    cursor: pointer;
    text-decoration: underline;
}
.adjust-margin {
    margin-top: 0 !important;
}
.cancel-btn-margin {
    padding: 20px 30px;
    text-align: right;
}


@media (min-width: 768px) {
    .activityDetail-form {
        width: 640px !important;
        margin: 20px auto;
    }
    .activityDetail-submitContainer {
        width: 100%;
        margin: 40px auto 0px auto;
        text-align: center;
    }
    .activityDetail-submitContainer .ui.button {
        width: 192px;
        margin: 0 10px!important;
    }
    .activityReviewDetails-container {
        width: 90% !important;
    }
    .select-beacon-label {
        text-align: right;
        font-weight: 600;
        line-height: 2.5;
    }

}

.contact-number .ui.input {
    width: 100% !important;
}

.activity-vehicleInfo{
    margin:30px 20px;
}

.activityReviewDetails-container{
    margin:20px 20px;
}

.activity-vehicleInfo .header{
    margin:10px 00px!important;
}