.side-menu .list > .item {
    padding: 5px 10px !important;
}
.side-menu .active.item {
    background-color: #0e1f47;
    color: #FFF;
}
.side-menu {
    text-align: left !important;
}
.side-menu .item:not(.no-click) {
    cursor: pointer;
}
.side-menu .ui.list .list, ol.ui.list ol, ul.ui.list ul,
.side-menu .ui.accordion:not(.styled) .accordion .title~.content:not(.ui), .ui.accordion:not(.styled) .title~.content:not(.ui),
.side-menu .ui.accordion .title:not(.ui),
.side-menu .accordion .accordion .item.no-click
{
    padding: 0 !important;
}
.side-menu .ui.accordion .accordion {
    margin: 0;
}
.side-menu .accordion .accordion > .content {
    margin-top: 5px !important;
    font-size: 0.85em !important;
}
.side-menu .ui.list, ol.ui.list, ul.ui.list{
    margin: 0;
}
.side-menu .accordion .accordion .item
{
    padding-left: 15px !important;
}
.side-menu i.dropdown.icon {
    float: right !important;
}
.side-menu .adjustMargin {
    margin-right: 7px !important;
}