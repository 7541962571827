.footer{
	background-color: #0f1f48;
	color:#FFF;
	padding: 15px;
}

.footer a{
	color:#FFF;
	font-size: 18px;
	cursor: pointer;
}
