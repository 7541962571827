.home-topMenu{
	/* height: 65px; */
	height: auto;
	padding:12px 18px 0px 18px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left top;
}

.home-topMenu-header{
	float:left;
	font-size: 16px;
	font-weight: bold;
	margin-left:5px;

}

.home-topMenu-headerBottom{
	color: #d33f3a;
}

.home-topMenu-rightSection{
	float: right;
	/* width: 600px; */
	display: none;
}

.home-topMenu-help{
	border:none!important;
}

/* .home-topBanner{
	background-color:#0e1f47;
	height: 200px;
	background-image: url('../assets/topmenu_planet.png');
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: left top;
}
.home-topBanner-welcomeMessage{
	color:#FFF;
	font-size:36px;
	padding: 40px 20% 40px 35%;
	line-height: 40px;
} */

.home-topBanner{
	background-color: #0e1f47;
    height: 150px;
    background-image: url(/static/media/topmenu_planet.30c69366.png);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: -42% 7vh;
	background-size: 70%;
}
.home-topBanner-welcomeMessage{
	color: #FFF;
    font-size: 1.5em;
    padding: 4% 10% 4% 35%;
    line-height: 1em;
    text-transform: uppercase;
}


.home-loggedInBanner{
	height: 30px;
	background-color: #1a2e66;
	color:#fff;
	padding:5px 30px;
}

.home-loggedInBannerlogout{
	text-transform: uppercase;
	color:red;
	cursor: pointer;
	float:right;
	margin-left:30px;
}

.home-loggedInBanner-username{
	float:right;
}

.print-only {
	display: none;
}

@media print {
	.noprint {
		/* visibility: hidden; */
	   	display: none !important;
	}
	@page {
		margin: 25px;
	}
	.print-only {
		display: block;
		text-align: center !important;
		margin-bottom: 15px;
	}
}

.editing-select,
.editing-select .dropdown,
.editing-input input,
.editing-input textarea,
.beaconDetailform-textarea {
	
}
.date-picker-inline {
	display: inline-block !important;
}
.adjust-icon {
	position: relative;
	top: -1px !important;
}
.clearFix {
	clear: both;
}
.burger-menu{
	display: block;
	float: right;
	/* margin-right: 0.4em; */
}
.burger-btn{
	padding: 0.2em !important;
}
.burger-icon{
	width: 1.5em !important;
    height: 1.5em !important;
    line-height: 1.5em !important;
    font-size: 1.5em !important;
}
.burger-menu-container{
	position: absolute;
	width: 100%;
	height: 100%;
}
.showPassword{
	opacity: 1 !important;
}
.showPassword::before{
	/* color: #db2828 !important; */
	color: black !important;
}


.for-mobile {
	display: block;
}
.for-desktop {
	display: none;
}

/* For Desktop */
@media only screen and (min-width: 768px){
	.home-topMenu-rightSection{
		display: block;
	}
	.burger-menu{
		display: none;
	}
	.home-topBanner{
		height: 200px;
		background-position: left top;
		background-size: unset;
	}
	.home-topBanner-welcomeMessage{
		color: #FFF;
		font-size: 2em;
		padding: 35px 10% 35px 45%;
		line-height: 1.2em;
	}

	.for-mobile {
		display: none !important;
	}
	.for-desktop {
		display: block !important;
	}
}

.home-offline-banner{
	background-color: #f39c12;
	text-align: center;
	width: 100%;
	padding: 4px 0;
}

.home-maintenance-banner{
	background-color: #d35400;
	text-align: center;
	width: 100%;
	padding: 4px 0;
}