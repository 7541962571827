html, body { height: 100%; margin: 0; padding: 0; }

#root{
	height: 100%;
}

.App {
 height: 100%;
}


.dropdown .icon{
	color:red;
}
.ui.basic.blue.button, .ui.basic.blue.buttons .button {
	box-shadow: none!important;
}


.imageUpload-header{
	position:absolute!important;
	left:50%;
	top:50%;
	transform:translate(-50%,-80%)
}

/* Essentials */
.defaultPageLayout-container { display: table; }
/* .content { display: table-row; height: 100%; } */
.defaultPageLayout-content:not(li) { display: table-row; height: 100%; } /* avoid affect to error message */
.defaultPageLayout-content-body { display: table-cell; }

/* Aestetics */
.defaultPageLayout-container { width: 100%; height: 100%; }

.hexValidateMessage p {
	white-space: pre-line;
}
