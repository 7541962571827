.centered * {
    text-align: center;
}
.activities-caption {
    color: #0d1e49;
    /* margin-bottom: 150px !important; */
}
.dashboard-activities thead th {
    background-color: #0d1e49!important;
	color:#fff!important;
	padding:25px 10px!important;
	border:1px solid #bdc3c7;
}

.modal-header {
    color: #0d1e49 !important;
    margin-bottom: 15px !important;
}

.dimmed.dimmable>.ui.animating.dimmer, .dimmed.dimmable>.ui.visible.dimmer, .ui.active.dimmer {
    overflow-y: scroll !important;
}