.faq-container .header {
	display: block !important;
}
.faq-container .ui.accordion.question-answer .title {
	font-weight: bold;
	font-size: 1.2em;
	padding-left: 1.2em;
}
.faq-container .ui.accordion.question-answer .title > i.dropdown.icon {
	position: absolute;
	left: 0.5em;
}
.faq-container .ui.accordion.question-answer .title~.content {
	padding: 1em 1em 1em 1.5em !important;
}
