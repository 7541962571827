
.contactList-cell-mobileHeader{
    display:none;
    width: 30%;
}

.contactList-cell-content{
    display:block;
}

.beacon-page-number{
    width: 60px;
    height: 30.8px;
}

.beacons-historySelect{
    font-size:1rem;
    float: right;
    margin: 4px 1em!important;
    width: 410px;
}

.beacons-historySelect-dropdown.ui.dropdown .menu>.header {
    font-size: 1.1em!important;
    font-weight: 900;
}



@media only screen and (max-width: 767px) {

    .contactList-cell-mobileHeader{
        display:block;
        background-color: #0d1e49;
        color:#FFF;
        text-align: right;
        width: 40%;
        padding: 5px 10px;
        border-top:1px solid rgba(255,255,255,0.2);
        float: left;
    }
    .contactList-cell-content{
        width: 60%;
        display:block;
        padding: 5px 5px;
        border-top:1px solid rgba(0,0,0,0.2);
        float: left;
    }
    .contactList-tableHeader{
        display:none!important;
    }
    .contactList-cell{
        clear: both;
        /* padding:0!important;
        text-align: left; */
    }
    .ui.table:not(.unstackable) tr{
        clear: both;
        padding-bottom: 0 !important;
    }
    .beacon-mobile{
        border: none !important;
    }

    .button-mobile{
        font-size: 2vw !important;
        /* padding: 1em !important; */
    }
    .pagination-mobile{
        margin-right: 5px;
    }
    .pagination-mobile,
    .pagination-mobile *{
        font-size: 2vw !important;
    }
    .pagination-mobile > .ui.menu.fluid{
        width: auto !important;
    }
    .beacon-page-number{
        width: 25px;
    }

    /* .ui.stackable.celled.grid>.row>.column.SearchCriteriaDisplay-column{
        padding: 0 1em !important;
    } */
    .ui.floating.label.beacon-searchCriteria-closeButton{
        margin: 1.75em 0 0 0;
    }
    .mobile-padding{
        padding: 0 1em !important;
    }

}

.dimmed.dimmable>.ui.animating.dimmer, .dimmed.dimmable>.ui.visible.dimmer, .ui.active.dimmer {
    overflow-y: scroll !important;
}

.per-page-box .visible.menu.transition {
    z-index: 999;
}