

.home-body{
	max-width: 1680px!important;
	margin:0 auto!important;
}

@media (max-width: 768px) {
	.home-body-divider{
		display: none;
	}
}

.home-satellite{
	display: block!important;
  margin-left: auto!important;
  margin-right: auto!important;
	width: 22%!important;
	height: 110px!important;
}

.home-helpButton{	
	display: inline-block!important;
	transform: translate(3px,-3px);
	background-color: #192f65 !important;
}

.home-regHex-label{
	text-align: center;
	margin:10px 0 10px 0!important;
	line-height: 25px;
}

.home-regHex-textArea{
	width: 50%!important;
	max-width: 25rem!important; 
}

.home-regHex-textArea textarea{
	resize: none!important;
	max-width: 25rem!important; 
}

.newBeacon-errorMessage{
	width: 100%!important;

}

.required-field-notation {
	text-align: right;
	color: red;
}

.ui.placeholder.segment.special-message {
	margin-top: 0;
	min-height: 0;
	background: #fff;
	box-shadow: none;
}
.ui.placeholder.segment.special-message.Homepage {	
	text-align: center;
}
.ui.placeholder.segment.special-message.Faq {
	border: none;
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0;
}

.home-faq-segment-cardGroup{
	margin:30px 30px !important;
}

.home-faq-segment-subHeader{
	width: 100% !important;
}

.home-faq-segment-cardGroup .ui.card .content{
	background-color: #0d1f47 !important;
	height: 240px;
	border-radius: 12.5px !important;
	padding: 40px 45px 0 45px !important;
}

.home-faq-segment-cardGroup .ui.card .content .description{
	color:#FFFFFF !important;
	font-size: 24px;
	text-align: center;
	line-height: 36px;
	vertical-align: middle !important;
	height: 100% !important;
}